import React, { ReactElement } from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";
export default function WhySeekDebtRelief(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  lg:gap-40 gap-8 lg:mt-28 mt-24 ">
            <div className="max-w-[550px] mx-auto lg:mx-0 order-2 lg:order-1">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#274DC2] ">
                    Why Should You Seek Debt Relief?
                </h2>

                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    Being in debt can feel overwhelming, and it might make you
                    feel like you're stuck in a never-ending cycle. But guess
                    what? There's good news! With a reliable and trustworthy
                    debt relief company, you can finally find the financial
                    freedom you've been longing for.
                </p>
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    Whether you're an individual or a business, there are debt
                    relief programs out there for you. From debt consolidation
                    to settlement, management plans to bankruptcy, student loan
                    forgiveness to mortgage relief, a savvy provider can
                    expertly guide you to the path that suits you best. They'll
                    offer the relief and freedom from debt that you truly
                    deserve. So why not take the first step towards a debt-free
                    life?
                </p>
                <div className="sm:mt-12 mt-8 text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
            <div className="w-[221px] h-[253px] md:w-[430px] md:min-w-[430px] md:h-[474px]  relative  mx-auto lg:mx-0 order-1 lg:order-2 lg:mt-12 ">
                <Image
                    src={"/debtreliefgate/assets/images/seekImg.png"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
